<template>
  <div>
    <div
      v-if="student"
      class="md-layout"
    >
      <div class="md-layout-item md-size-33">
        <md-card
          class="md-card-profile"
        >
          <div class="md-card-avatar">
            <div v-if="!student.profile_image">
              <img
                :src="avatar"
                class="picture-src"
                title=""
              >
            </div>
            <div v-else>
              <img :src="student.profile_image.url">
            </div>
          </div>

          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item">
                <h3 class="mt-2 mb-4">
                  <b>{{ student.first_name }} {{ student.last_name }}</b>
                </h3>
              </div>
            </div>
            <div class="md-layout text-left">
              <div class="md-layout-item">
                <b>Email: </b> {{ student.email }}<br>
                <b>Nationality: </b> {{ student.nationality }}<br>
                <b>Birth: </b> {{ student.birth_date }}<br>
                <b>Gender: </b> {{ student.gender === 'M' ? 'Male' : 'Female' }}<br>
                <b>Phone: </b> {{ student.phone }}<br>
                <b>Emergency Contact: </b>
                {{ student.emergency_contact }}
                {{ student.emergency_contact_name ? `(${student.emergency_contact_name})` : '' }}
                <br>
                <b>Biometric Saved: </b> {{ student.biometric_saved ? 'YES' : 'NO' }}<br>
                <b>App Installed: </b> {{ student.app_registration_date ? 'YES' : 'NO' }}
                <template v-if="student.app_registration_date">
                  (EMAIL: {{ student.app_email }})<br>
                </template>
              </div>
            </div>
          </md-card-content>
        </md-card>

        <md-card v-if="irp_card">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              IRP Card
            </h4>
          </md-card-header>
          <md-card-content>
            <FormNumber
              v-model="irp_card.reg_number"
              icon="tag"
              label="Registration Number"
              :disabled="!editting_irp"
            />
            <FormDate
              v-model="irp_card.issue_date"
              label="Issue Date"
              :disabled="!editting_irp"
            />
            <FormDate
              v-model="irp_card.expiration_date"
              label="Expiration Date"
              :disabled="!editting_irp"
            />
            <FormSelect
              v-model="irp_card.stamp"
              icon="approval"
              label="Stamp"
              :options="stampsOpts"
              :disabled="!editting_irp"
            />

            <md-button
              v-if="!editting_irp"
              class="md-info md-sm md-block"
              @click="editting_irp = true"
            >
              Update
            </md-button>
            <md-button
              v-if="!editting_irp"
              class="md-danger md-sm md-block"
              @click="removeIRP"
            >
              Delete
            </md-button>
            <md-button
              v-if="editting_irp"
              class="md-success md-sm md-block"
              @click="saveIRP"
            >
              <span class="md-label">
                <md-icon class="material-icons mr-2">save</md-icon>
              </span>
              Save IRP Card
            </md-button>
          </md-card-content>
        </md-card>

        <md-button
          v-if="student.app_registration_date"
          class="md-danger w-100"
          @click="removeStudentAppAccount"
        >
          <span class="md-label">
            <md-icon class="material-icons mr-2">delete</md-icon>
          </span>
          Delete app account
        </md-button>

        <md-button
          class="md-warning w-100"
          @click="$router.push({name:'EditStudent', params: {id: student.id}})"
        >
          <span class="md-label">
            <md-icon class="material-icons mr-2">edit</md-icon>
          </span>
          Edit Profile
        </md-button>

        <md-button
          v-if="!irp_card"
          class="md-info w-100"
          @click="newIRPCard"
        >
          <span class="md-label">
            <md-icon class="material-icons mr-2">add</md-icon>
          </span>
          Add IRP Card
        </md-button>

        <md-button
          class="md-primary w-100"
          @click="showExamForm = true"
        >
          <span class="md-label">
            <md-icon class="material-icons mr-2">add</md-icon>
          </span>
          Add End of Programme Exam
        </md-button>

        <md-button
          class="md-rose w-100"
          @click="showAccommodationForm = true"
        >
          <span class="md-label">
            <md-icon class="material-icons mr-2">add</md-icon>
          </span>
          Add Accommodation
        </md-button>
      </div>

      <div class="md-layout-item md-size-66">
        <md-card v-if="student.flight">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              Flight
            </h4>
          </md-card-header>
          <md-card-content>
            <div
              class="md-layout"
            >
              <div class="md-layout-item">
                <md-table
                  table-header-color="green"
                >
                  <md-table-row>
                    <md-table-head>Flight Code</md-table-head>
                    <md-table-head>Flight Date</md-table-head>
                    <md-table-head>Arrival Date</md-table-head>
                    <md-table-head>Airline</md-table-head>
                    <md-table-head v-if="student.flight.file">
                      File
                    </md-table-head>
                  </md-table-row>
                  <md-table-row>
                    <md-table-cell>
                      {{ student.flight.flight_code }}
                    </md-table-cell>
                    <md-table-cell>
                      {{ dateTimeFormat(student.flight.departure_date) }}
                    </md-table-cell>
                    <md-table-cell>
                      {{ dateTimeFormat(student.flight.arrival_date) }}
                    </md-table-cell>
                    <md-table-cell>
                      {{ student.flight.airline }}
                    </md-table-cell>
                    <md-table-cell v-if="student.flight.file">
                      <md-button
                        class="md-simple md-icon-button md-info"
                        @click="openFileUrl(student.flight.file_id)"
                      >
                        <md-icon>
                          visibility
                        </md-icon>
                        <md-tooltip>Preview voucher</md-tooltip>
                      </md-button>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </md-card-content>
        </md-card>

        <md-card v-if="student.passports.length">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              Passports
            </h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item">
                <md-table
                  v-model="student.passports"
                  table-header-color="green"
                >
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                  >
                    <md-table-cell md-label="Passport number">
                      {{ item.number }}
                    </md-table-cell>
                    <md-table-cell md-label="Country">
                      {{ item.country.name }}
                    </md-table-cell>
                    <md-table-cell md-label="Issue date">
                      {{ moment(item.issue_date) }}
                    </md-table-cell>
                    <md-table-cell md-label="Expiration date">
                      {{ moment(item.expiration_date) }}
                    </md-table-cell>
                    <md-table-cell md-label="Default">
                      <md-radio
                        v-model="defaultPassport"
                        :value="item.passport_id"
                        @change="onChange(item.passport_id, itemType.PASSPORT)"
                      />
                    </md-table-cell>
                    <md-table-cell>
                      <md-button
                        v-if="item.file"
                        class="md-simple md-icon-button md-info"
                        @click="openFileUrl(item.file.file_id)"
                      >
                        <md-icon>
                          visibility
                        </md-icon>
                        <md-tooltip>Preview passport</md-tooltip>
                      </md-button>

                      <md-button
                        class="md-simple md-icon-button md-danger"
                        @click="onDelete(item.passport_id, itemType.PASSPORT)"
                      >
                        <md-icon>close</md-icon>
                        <md-tooltip>Delete passport</md-tooltip>
                      </md-button>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </md-card-content>
        </md-card>

        <md-card v-if="student.addresses.length">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              Addresses
            </h4>
          </md-card-header>
          <md-card-content>
            <div
              class="md-layout"
            >
              <div class="md-layout-item">
                <md-table
                  v-model="student.addresses"
                  table-header-color="green"
                >
                  <md-table-row
                    slot="md-table-row"
                    slot-scope="{ item }"
                  >
                    <md-table-cell md-label="Address">
                      {{ item.address }}
                    </md-table-cell>
                    <md-table-cell md-label="Country">
                      {{ item.country.name }}
                    </md-table-cell>
                    <md-table-cell md-label="County">
                      {{ item.county }}
                    </md-table-cell>
                    <md-table-cell md-label="City">
                      {{ item.city }}
                    </md-table-cell>
                    <md-table-cell md-label="Zip Code">
                      {{ item.zip_code }}
                    </md-table-cell>
                    <md-table-cell md-label="Type">
                      {{ item.type }}
                    </md-table-cell>
                    <md-table-cell md-label="Default">
                      <md-radio
                        v-model="defaultAddress"
                        :value="item.student_address_id"
                        @change="onChange(item.student_address_id, itemType.ADDRESS)"
                      />
                    </md-table-cell>
                    <md-table-cell>
                      <md-button
                        class="md-simple md-icon-button md-danger"
                        @click="onDelete(item.student_address_id, itemType.ADDRESS)"
                      >
                        <md-icon>close</md-icon>
                        <md-tooltip>Delete address</md-tooltip>
                      </md-button>
                    </md-table-cell>
                  </md-table-row>
                </md-table>
              </div>
            </div>
          </md-card-content>
        </md-card>

        <md-card v-if="placement_tests.length">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              Placement Tests
            </h4>
          </md-card-header>
          <md-card-content>
            <md-table
              v-model="placement_tests"
              table-header-color="green"
            >
              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
              >
                <md-table-cell md-label="Title">
                  {{ item.exam.title }}
                </md-table-cell>
                <md-table-cell md-label="Finished Date">
                  {{ moment(item.finished_at) }}
                </md-table-cell>
                <md-table-cell md-label="Score">
                  {{ item.score }}%
                </md-table-cell>
                <md-table-cell md-label="Level">
                  {{ item.level.name }}
                </md-table-cell>
                <md-table-cell md-label="">
                  <md-button
                    class="md-success md-just-icon mr-2"
                    @click="onShowAnswersPercentage(item)"
                  >
                    <md-icon>percent</md-icon>
                    <md-tooltip>Show answers percentage</md-tooltip>
                  </md-button>

                  <md-button
                    class="md-primary md-just-icon"
                    @click="onShowAnswers(item)"
                  >
                    <md-icon>visibility</md-icon>
                    <md-tooltip>Show answers</md-tooltip>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>

        <md-card v-if="end_exams.length">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              End of Programme Exams
            </h4>
          </md-card-header>
          <md-card-content>
            <md-table
              table-header-color="green"
            >
              <md-table-row>
                <md-table-head>Programme</md-table-head>
                <md-table-head>Date of Exam</md-table-head>
                <md-table-head>Result</md-table-head>
                <md-table-head>Invoice</md-table-head>
                <md-table-head>Exam Type</md-table-head>
                <md-table-head>Created</md-table-head>
                <md-table-head />
              </md-table-row>
              <md-table-row
                v-for="e of end_exams"
                :key="e.student_end_exam_id"
              >
                <md-table-cell>
                  {{
                    !e.programme ?
                      '-' :
                      `${dateFormat(e.programme.start_date)} |
                    ${e.programme.course.title} "${e.programme.status}"`
                  }}
                </md-table-cell>
                <md-table-cell>
                  {{ dateFormat(e.date) }}
                </md-table-cell>
                <md-table-cell>
                  {{ e.result }}
                </md-table-cell>
                <md-table-cell>
                  {{ e.invoice }}
                </md-table-cell>
                <md-table-cell>
                  {{ e.exam_type.name }}
                </md-table-cell>
                <md-table-cell>
                  {{ dateFormat(e.created_at) }}
                </md-table-cell>

                <md-table-cell class="actions-table-cell">
                  <md-button
                    v-if="e.file"
                    class="md-primary md-just-icon"
                    @click="openFileUrl(e.file.file_id)"
                  >
                    <md-tooltip>Preview Certificate</md-tooltip>
                    <md-icon>visibility</md-icon>
                  </md-button>

                  <md-button
                    class="md-info md-just-icon"
                    @click="editExam(e)"
                  >
                    <md-tooltip>Update Exam</md-tooltip>
                    <md-icon>edit</md-icon>
                  </md-button>

                  <md-button
                    class="md-danger md-just-icon"
                    @click="deleteExitExam(e)"
                  >
                    <md-tooltip>Delete Exam</md-tooltip>
                    <md-icon>close</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
        <md-card v-if="accommodations.length">
          <md-card-header>
            <h4
              class="title"
              style="font-weight:bold"
            >
              Accommodations
            </h4>
          </md-card-header>
          <md-card-content>
            <md-table
              table-header-color="green"
            >
              <md-table-row>
                <md-table-head>Accommodation Type</md-table-head>
                <md-table-head>Check in Date</md-table-head>
                <md-table-head>Check out Date</md-table-head>
                <md-table-head>Weeks</md-table-head>
                <md-table-head>Address</md-table-head>
                <md-table-head>Airport Transfer</md-table-head>
                <md-table-head>Booked</md-table-head>
                <md-table-head />
              </md-table-row>
              <md-table-row
                v-for="e of accommodations"
                :key="e.student_accommodation_id"
              >
                <md-table-cell>
                  {{ e.accommodation_type.name }}
                </md-table-cell>
                <md-table-cell>
                  {{ dateFormat(e.check_in) }}
                </md-table-cell>
                <md-table-cell>
                  {{ dateFormat(e.check_out) }}
                </md-table-cell>
                <md-table-cell>
                  {{ e.weeks }}
                </md-table-cell>
                <md-table-cell>
                  {{ e.address }}
                </md-table-cell>
                <md-table-cell>
                  {{ e.airport_transfer === 1 ? 'YES':'NO' }}
                </md-table-cell>
                <md-table-cell>
                  {{ e.booked }}
                </md-table-cell>
                <md-table-cell class="actions-table-cell">
                  <div class="actions">
                    <md-button
                      class="md-info md-just-icon"
                      @click="showAccommodationDetails(e)"
                    >
                      <md-tooltip>Details</md-tooltip>
                      <md-icon>info</md-icon>
                    </md-button>

                    <md-button
                      v-if="e.booked === 'NO'"
                      class="md-info md-just-icon"
                      @click="editAccommodationDetails(e)"
                    >
                      <md-tooltip>Edit</md-tooltip>
                      <md-icon>edit</md-icon>
                    </md-button>

                    <md-button
                      v-if="e.booked === 'NO'"
                      class="md-danger md-just-icon"
                      @click="deleteAccommodation(e)"
                    >
                      <md-tooltip>Delete Accommodation</md-tooltip>
                      <md-icon>close</md-icon>
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout">
        <div class="md-layout-item text-center">
          <router-link
            :to="{name:'StudentResume'}"
            tag="md-button"
            class="md-primary"
          >
            Return
          </router-link>
        </div>
      </div>

      <placement-answers
        v-if="selTest"
        :test="selTest"
        @close="selTest=null"
      />

      <EndExamForm
        v-if="showExamForm"
        :exam="selectExam"
        @close="closeEndExamForm"
        @onSave="getExitExams"
      />
      <CreateAccommodation
        v-if="showAccommodationForm"
        :accommodation="selectAccommodation"
        @close="closeAccommodationForm"
        @onSave="getAccommodation"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  FormDate,
  FormSelect,
  FormNumber,
} from '@/components/Inputs';
import PlacementAnswers from './PlacementAnswers.vue';
import CreateAccommodation from './CreateAccommodation.vue';
import EndExamForm from './EndExamForm.vue';

export default {
  components: {
    FormDate,
    FormSelect,
    FormNumber,
    PlacementAnswers,
    CreateAccommodation,
    EndExamForm,
  },

  data() {
    return {
      student: null,
      avatar: '/img/default-avatar.png',
      itemType: {
        PASSPORT: 'Passport',
        ADDRESS: 'Address',
      },
      stampsOpts: [
        { id: 'STAMP 1/1G', name: 'STAMP 1/1G' },
        { id: 'STAMP 2', name: 'STAMP 2' },
        { id: 'STAMP 4', name: 'STAMP 4' },
        { id: 'EUROPEAN CITIZENSHIP', name: 'EUROPEAN CITIZENSHIP' },
        { id: 'OTHER', name: 'OTHER' },
      ],
      placement_tests: [],
      end_exams: [],
      accommodations: [],
      selTest: null,
      defaultPassport: null,
      defaultAddress: null,
      irp_card: null,
      editting_irp: false,
      showExamForm: false,
      showAccommodationForm: false,
      selectExam: null,
      selectAccommodation: null,
    };
  },
  mounted() {
    this.getStudent(this.$route.params.id);
    this.getPlacementTest();
    this.getExitExams();
    this.getAccommodation();
  },
  methods: {
    removeStudentAppAccount() {
      this.fireConfirm(
        'Deleting Student App account',
        'The Student won\'t be able to sign in anymore, are you sure?',
      ).then(() => {
        this.request(`app/account/${this.$route.params.id}`,
          'delete',
          null,
          (data) => {
            this.fireSuccess(data);
            this.getStudent(this.$route.params.id);
          });
      });
    },
    getStudent(id) {
      this.$nextTick(() => {
        this.request(`${this.$API.STUDENTS}${id}`, 'get', null, ({ data }) => {
          if (data.flight && data.flight.file === null) {
            data.flight.file = {
              url: null,
            };
          }

          this.student = {
            id: data.student_id,
            first_name: data.first_name,
            last_name: data.last_name,
            nationality: data.country.nationality,
            gender: data.gender,
            phone: data.phone,
            emergency_contact: data.emergency_contact,
            emergency_contact_name: data.emergency_contact_name,
            email: data.email,
            birth_date: moment(String(data.date_of_birth))
              .format(this.$store.getters['school_settings/settings'].date_format),
            passports: data.passports,
            addresses: data.addresses,
            flight: data.flight,
            profile_image: data.profile_image,
            biometric_saved: data.biometric_saved,
            app_registration_date: data.app_registration_date,
            app_email: data.app_email,
          };

          const passportFound = data.passports.find(
            (item) => item.is_default === true,
          );
          this.defaultPassport = passportFound === undefined ? null : passportFound.passport_id;

          const addressFound = data.addresses.find(
            (item) => item.is_default === true,
          );
          this.defaultAddress = addressFound === undefined ? null : addressFound.student_address_id;

          if (data.gnib) {
            this.irp_card = data.gnib;
          }

          this.$forceUpdate();
        });
      });
    },
    getExitExams() {
      this.request(`students/${this.$route.params.id}/end_exam`, 'get', null, ({ data }) => {
        this.end_exams = data;
      });
    },
    getAccommodation() {
      this.request(`student_accommodations/${this.$route.params.id}`, 'get', null, ({ data }) => {
        this.accommodations = data;
      });
    },
    editExam(exam) {
      this.showExamForm = true;
      this.selectExam = exam;
    },
    closeEndExamForm() {
      this.showExamForm = false;
      this.selectExam = null;
    },
    closeAccommodationForm() {
      this.showAccommodationForm = false;
      this.selectAccommodation = null;
    },
    deleteExitExam(exam) {
      this.fireConfirm(
        'Deleting End of Programme Exam',
        'The Exam data will be lost, are you sure?',
      ).then(() => {
        this.request(`${this.$API.STUDENTS}${this.$route.params.id}/end_exam/${exam.student_end_exam_id}`,
          'delete',
          null,
          (data) => {
            this.fireSuccess(data);
            this.getExitExams();
          });
      });
    },
    deleteAccommodation(accommodation) {
      this.fireConfirm(
        'Deleting Accommodation',
        'The Accommodation data will be lost, are you sure?',
      ).then(() => {
        this.request(`student_accommodations/${accommodation.student_accommodation_id}`,
          'delete',
          null,
          (data) => {
            this.fireSuccess(data);
            this.getAccommodation();
          });
      });
    },
    onDelete(id, itemType) {
      this.fireConfirm(
        `Deleting ${itemType}`,
        `Are you sure you want to delete this ${itemType}?`,
      ).then(() => {
        let url = '';

        if (itemType === this.itemType.PASSPORT) {
          url = `${this.$API.PASSPORTS}${id}`;
        } else if (itemType === this.itemType.ADDRESS) {
          url = `${this.$API.STUDENT_ADDRESS}${id}`;
        }

        this.request(url, 'delete', null, () => {
          this.fireSuccess(`${itemType} deleted succesfully`);
          this.getStudent(this.$route.params.id);
        });
      });
    },
    onChange(id, itemType) {
      let url = '';

      if (itemType === this.itemType.PASSPORT) {
        url = `${this.$API.PASSPORTS}${this.$route.params.id}/passport/${id}`;
      } else if (itemType === this.itemType.ADDRESS) {
        url = `${this.$API.STUDENT_ADDRESS}${this.$route.params.id}/address/${id}`;
      }
      this.request(url, 'put', null, () => {
        this.fireSuccess(`${itemType} updated succesfully`);
        this.getStudent(this.$route.params.id);
      });
    },
    onReturn() {
      this.$router.push({ name: 'Students' });
    },
    moment(date) {
      return moment(date).format(this.$store.getters['school_settings/settings'].date_format);
    },
    getPlacementTest() {
      this.request(this.$API.PLACEMENT_TEST + this.$route.params.id, 'get', null, (res) => {
        res.data.forEach((x) => {
          if (x.is_finished) this.placement_tests.push(x);
        });
      });
    },
    onShowAnswersPercentage(item) {
      const levelsTotals = item.answers.reduce((a, b) => {
        if (!a[b.question.level_id]) {
          a[b.question.level_id] = {
            name: b.question.level.name,
            total: 1,
            correct: b.answer.is_correct ? 1 : 0,
          };
        } else {
          a[b.question.level_id].total += 1;
          a[b.question.level_id].correct += b.answer.is_correct ? 1 : 0;
        }

        return a;
      }, {});

      const levelsPercent = Object.values(levelsTotals)
        .map((x) => ({
          title: x.name,
          total: x.total,
          correct: x.correct,
        }));

      this.fireModal({
        title: 'Answers Percentage per Level',
        html: `<ul style="list-style: none; display: flex; flex-direction: column; gap: .5em; padding: 0; margin: 0;">
          ${levelsPercent.map((x) => `<li><b>${x.title}:</b> ${x.correct}/${x.total} (${((x.correct * 100) / x.total).toFixed(2)}%)</li>`).join('')}
        </ul>`,
      });
    },
    onShowAnswers(item) {
      this.selTest = item;
    },
    newIRPCard() {
      this.irp_card = {
        reg_number: null,
        issue_date: null,
        expiration_date: null,
        stamp: null,
      };
    },
    removeIRP() {
      this.request(`${this.$API.STUDENTS}${this.$route.params.id}/gnib/${this.irp_card.student_gnib_id}`,
        'delete',
        null,
        () => {
          this.irp_card = null;
          this.fireSuccess('IRP removed succesfully!');
        });
    },
    saveIRP() {
      if (this.irp_card.student_gnib_id) {
        this.request(`${this.$API.STUDENTS}${this.$route.params.id}/gnib/${this.irp_card.student_gnib_id}`,
          'put',
          this.irp_card,
          () => {
            this.editting_irp = false;
            this.fireSuccess('IRP updated succesfully!');
            this.getStudent(this.$route.params.id);
          });
      } else {
        this.request(`${this.$API.STUDENTS}${this.$route.params.id}/gnib`,
          'post',
          this.irp_card,
          () => {
            this.editting_irp = false;
            this.fireSuccess('IRP created succesfully!');
            this.getStudent(this.$route.params.id);
          });
      }
    },
    showAccommodationDetails(a) {
      this.fireModal({
        title: 'Accommodation Details',
        html: `<ul style="list-style: none; display: flex; flex-direction: column; gap: .5em; text-align: left; padding: 0; margin: 0;">
          <li><b>Accommodation Type:</b> ${a.accommodation_type.name}</li>
          <li><b>Check In:</b> ${this.dateFormat(a.check_in)}</li>
          <li><b>Check Out:</b> ${this.dateFormat(a.check_out)}</li>
          <li><b>Weeks:</b> ${a.weeks}</li>
          <li><b>Address:</b> ${a.address}</li>
          <li><b>Airport Transfer:</b> ${a.airport_transfer ? 'YES' : 'NO'}</li>
          ${a.airport_transfer ? `
            <li><b>Driver Name:</b> ${a.driver_name}</li>
            <li><b>Driver Phone:</b> ${a.driver_phone}</li>
            <li><b>Driver Memo:</b> ${a.driver_memo}</li>` : ''}
        </ul>`,
      });
    },
    editAccommodationDetails(accommodation) {
      this.showAccommodationForm = true;
      this.selectAccommodation = accommodation;
    },
  },
};
</script>

<style scoped>
.actions-table-cell {
  width: 1%;
}
.actions-table-cell .actions {
  display: flex;
  flex-wrap: nowrap;
  gap: .25em;
}
.actions-table-cell .actions >>> button {
  margin: 0 !important;
}
</style>
