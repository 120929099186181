<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      Accommodation
    </template>
    <template slot="body">
      <AccommodationTypeSelect
        v-model="form.accommodation_type_id"
      />
      <StudentProgrammesSelect
        v-model="form.programme_id"
        :student-id="$route.params.id"
      />
      <FormDate
        v-model="form.check_in"
        label="Check in date"
      />
      <FormDate
        v-model="check_out"
        label="Check out date"
        disabled
      />
      <FormNumber
        v-model="form.weeks"
        label="Weeks"
        icon="date_range"
      />
      <FormText
        v-model="form.address"
        label="Address"
        icon="pin_drop"
      />
      <FormSelect
        v-model="form.airport_transfer"
        label="Airport Transfer"
        icon="tag"
        :options="options_Accomodation"
      />
      <template v-if="form.airport_transfer">
        <FormText
          v-model="form.driver_name"
          label="Driver name"
          icon="person"
        />
        <FormText
          v-model="form.driver_phone"
          label="Driver phone"
          icon="phone"
        />
        <FormTextarea
          v-model="form.driver_memo"
          label="Driver memo"
          icon="info"
        />
      </template>
    </template>
    <template slot="footer">
      <md-button
        class="md-danger"
        @click="close"
      >
        Cancel
      </md-button>

      <md-button
        class="md-success"
        @click="saveForm"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormDate,
  FormSelect,
  FormText,
  FormNumber,
  FormTextarea,
} from '@/components/Inputs';

import {
  StudentProgrammesSelect,
  AccommodationTypeSelect,
} from '@/components/Inputs/selects';

import moment from 'moment';

export default {
  components: {
    FormDate,
    FormSelect,
    FormText,
    StudentProgrammesSelect,
    FormNumber,
    FormTextarea,
    AccommodationTypeSelect,
  },
  props: {
    accommodation: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      student_accommodation_id: null,
      check_in: null,
      weeks: 1,
      airport_transfer: false,
      accommodation_type_id: null,
      programme_id: null,
    },
    options_Accomodation: [
      { id: true, name: 'Yes' },
      { id: false, name: 'No' },
    ],
  }),
  computed: {
    check_out() {
      if (!this.form.check_in) return null;

      return moment(this.form.check_in).add(this.form.weeks, 'weeks');
    },
  },
  mounted() {
    if (this.accommodation) {
      this.form = {
        student_accommodation_id: this.accommodation.student_accommodation_id,
        check_in: moment(this.accommodation.check_in),
        weeks: this.accommodation.weeks,
        airport_transfer: !!this.accommodation.airport_transfer,
        driver_name: this.accommodation.driver_name,
        driver_phone: this.accommodation.driver_phone,
        driver_memo: this.accommodation.driver_memo,
        accommodation_type_id: this.accommodation.accommodation_type_id,
        programme_id: this.accommodation.programme_id,
        address: this.accommodation.address,
      };
    } else {
      this.form.check_in = moment();
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveForm() {
      let valid = true;
      if (!this.form.check_in) {
        this.fireError('Select a check in date to continue');
        valid = false;
      }
      if (!this.form.weeks) {
        this.fireError('Weeks Attribute is required');
        valid = false;
      }
      if (!this.form.accommodation_type_id) {
        this.fireError('Select an Accommodation Type to continue');
        valid = false;
      }
      if (!this.form.programme_id) {
        this.fireError('Select a Programme to continue');
        valid = false;
      }

      if (valid) {
        const data = {
          ...this.form,
          check_in: moment(this.form.check_in).format('YYYY-MM-DD'),
          student_id: this.$route.params.id,
        };

        if (this.form.student_accommodation_id) {
          this.request(`student_accommodations/${this.form.student_accommodation_id}`, 'put', data, () => {
            this.fireSuccess('Accommodation edited successfully!');
            this.$emit('onSave');
            this.close();
          });
        } else {
          this.request('student_accommodations', 'post', data, () => {
            this.fireSuccess('Accommodation created successfully!');
            this.$emit('onSave');
            this.close();
          });
        }
      }
    },
  },
};
</script>
