<template>
  <vue-modal
    ref="modal"
    size="md"
    @close="close"
  >
    <template slot="title">
      Placement Test - {{ test.score }}% | {{ test.level.name }}
    </template>
    <template slot="body">
      <md-list
        class="md-triple-line"
      >
        <template
          v-for="(q,i) of test.answers"
        >
          <md-list-item
            :key="i+'_q'"
            :class="{'error': !q.answer.is_correct}"
          >
            <div class="md-list-item-text">
              <h3>#{{ i+1 }} - {{ trimHtml(q.question.question) }}</h3>
              <p v-if="q.question.help_text">
                {{ q.help_text }}
              </p>

              <div
                class="answer"
              >
                Answer: {{ q.answer.text }}
              </div>
            </div>
          </md-list-item>

          <md-divider
            :key="i"
            class="md-full"
          />
        </template>
      </md-list>
    </template>
    <template slot="footer" />
  </vue-modal>
</template>
<script>
import stringMix from '@/mixins/string';

export default {
  mixins: [stringMix],
  props: {
    test: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>
<style scoped>
.answer {
  margin-top: 10px;
}

::v-deep .md-list-item.error {
  background-color: #ff000047;
}
</style>
