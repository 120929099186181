<template>
  <vue-modal
    ref="modal"
    size="sm"
    @close="$emit('close')"
  >
    <template slot="title">
      New End of Programme Exam
    </template>
    <template slot="body">
      <FormDate
        v-model="form.date"
        label="Exam Date"
      />
      <FormText
        v-model="form.result"
        label="Result"
        icon="percent"
      />
      <FormText
        v-model="form.invoice"
        label="Invoice"
        icon="tag"
      />
      <ExamTypesSelect
        v-model="form.exam_type_id"
        @ready="onExamTypesLoad"
      />
      <StudentProgrammesSelect
        v-model="form.programme_id"
        :student-id="$route.params.id"
      />

      <div class="d-flex">
        <FormUploader
          @input="onFileUploaded"
        >
          <md-button class="md-info">
            <md-icon>file_present</md-icon>
            Upload Exam Certificate
          </md-button>
        </FormUploader>
        <md-button
          v-if="file_url"
          :href="file_url"
          target="_blank"
          class="md-primary md-just-icon"
        >
          <md-tooltip>Preview</md-tooltip>
          <md-icon>visibility</md-icon>
        </md-button>
      </div>
      <small>File must be JPG/PNG/PDF max 5MB</small>
    </template>
    <template slot="footer">
      <md-button
        class="md-danger"
        @click="close"
      >
        Cancel
      </md-button>

      <md-button
        class="md-success"
        @click="saveForm"
      >
        Save
      </md-button>
    </template>
  </vue-modal>
</template>
<script>
import {
  FormDate,
  FormText,
  FormUploader,
} from '@/components/Inputs';

import {
  StudentProgrammesSelect,
  ExamTypesSelect,
} from '@/components/Inputs/selects';

import moment from 'moment';

export default {
  components: {
    FormDate,
    FormText,
    FormUploader,
    StudentProgrammesSelect,
    ExamTypesSelect,
  },
  props: {
    exam: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      student_end_exam_id: null,
      date: moment(),
      result: null,
      invoice: null,
      exam_type_id: null,
      programme_id: null,
      file: null,
    },
    file_url: null,
    exam_types: [],
  }),
  beforeMount() {
    if (this.exam) this.form = { ...this.exam };
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    saveForm() {
      let valid = true;
      if (!this.form.date) {
        this.fireError('Select a date to continue');
        valid = false;
      }
      if (!this.form.result) {
        this.fireError('Result Attribute is required');
        valid = false;
      }
      if (!this.form.exam_type_id) {
        this.fireError('Select an Exam Type to continue');
        valid = false;
      }

      if (valid) {
        const formData = new FormData();
        formData.append('file', this.form.file);
        formData.append('date', moment(this.form.date).format('YYYY-MM-DD'));
        formData.append('result', this.form.result);
        formData.append('invoice', this.form.invoice);
        formData.append('exam_type_id', this.form.exam_type_id);
        formData.append('programme_id', this.form.programme_id);

        if (this.form.student_end_exam_id) {
          this.request(`students/${this.$route.params.id}/end_exam/${this.form.student_end_exam_id}`, 'put', formData, () => {
            this.fireSuccess('End Exam edited successfully!');
            this.$emit('onSave');
            this.close();
          });
        } else {
          this.request(`students/${this.$route.params.id}/end_exam`, 'post', formData, () => {
            this.fireSuccess('End Exam created successfully!');
            this.$emit('onSave');
            this.close();
          });
        }
      }
    },

    onFileUploaded(file) {
      this.form.file = file;
      this.file_url = URL.createObjectURL(file);
    },

    onExamTypesLoad(options) {
      if (options?.length === 1) {
        this.form.exam_type_id = options[0].id;
      }
    },
  },
};
</script>
<style></style>
