import { render, staticRenderFns } from "./StudentProfile.vue?vue&type=template&id=90f7f59c&scoped=true&"
import script from "./StudentProfile.vue?vue&type=script&lang=js&"
export * from "./StudentProfile.vue?vue&type=script&lang=js&"
import style0 from "./StudentProfile.vue?vue&type=style&index=0&id=90f7f59c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90f7f59c",
  null
  
)

export default component.exports